.Home-logo {
  height: 10vmin;
  pointer-events: none;
}

.Home-body {
  min-height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  vertical-align: center;
  justify-items: center;
  justify-self: center;
  flex-direction: column;
  justify-content: center;
}

.table {
  margin: 60px;
  padding: 20px;
}

.Home-buttons {
  display: flex;
  min-height: 20vh;
  align-items: center;
  vertical-align: center;
  justify-items: center;
  justify-self: center;
  flex-direction: column;
  justify-content: center;
  font-size: calc(5px + 2vmin);
}

.Home-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
